import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import Layout from "components/Layout";
import ProjectCard from "components/ProjectCard";
import Newsletter from "components/newsletter";


const WorkTitle = styled("h1")`
    margin-bottom: 1em;
`
const description='Projects & Experiments created by Koby Ofek';

const Work = ({ projects, meta }) => (
    <>
    <Helmet>
        <title>Projects By Koby Ofek</title>
        <meta name="description" content={description} />
        <meta name="og:title" content='Articles By Koby Ofek' />
        <meta name="og:description" content={description} />
        <meta name="og:type" content='website' />
        <meta name="twitter:card" content='summary' />
        <meta name="twitter:creator" content='@kobyof' />
        <meta name="twitter:title" content='Articles By Koby Ofek' />
        <meta name="twitter:description" content={description} />
    </Helmet>

        <Layout>
            <WorkTitle>
                Work
            </WorkTitle>
            <>
                {projects.map((project, i) => (
                    <ProjectCard
                        key={i}
                        category={project.node.project_category}
                        title={project.node.project_title}
                        description={project.node.project_preview_description}
                        thumbnail={project.node.project_preview_thumbnail}
                        uid={project.node._meta.uid}
                    />
                ))}
            </>
            <br />
              <Newsletter />
        </Layout>
    </>
);

export default ({ data }) => {
    const projects = data.prismic.allProjects.edges;
    const meta = data.site.siteMetadata;
    if (!projects) return null;

    return (
        <Work projects={projects} meta={meta}/>
    )
}

Work.propTypes = {
    projects: PropTypes.array.isRequired,
};

export const query = graphql`
    {
        prismic {
            allProjects {
                edges {
                    node {
                        project_title
                        project_preview_description
                        project_preview_thumbnail
                        project_category
                        project_post_date
                        _meta {
                            uid
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                description
                author
            }
        }
    }
`
